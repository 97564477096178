<template lang="">
    <div>
        <Detail />
    </div>
</template>
<script>
export default {
    components:{
        Detail: () => import("@/components/transfer/PropertyDetail")
    }
}
</script>
<style lang="">
    
</style>